import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import UploadIcon from '@material-ui/icons/CloudUpload';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

export default class SimpleBottomNavigation extends React.Component {
  render(){
    return (
      <BottomNavigation
        value={ this.props.state.page }
        onChange={(event, page) => {
          this.props.changePage(page);
        }}
        showLabels
      >
        <BottomNavigationAction label="Details" icon={<BubbleChartIcon />} />
        <BottomNavigationAction label="Upload" icon={<UploadIcon />} />
      </BottomNavigation>
    );
  }
}
