import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';

class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.formControl = {
      margin: 1,
      minWidth: 120,
    };
    this.items = this.range(2000, 2020)
    this.state = {
      startyear: this.props.startyear,
      endyear: this.props.endyear,
      cluster: this.props.cluster
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClusterChange = this.handleClusterChange.bind(this);
    this.render = this.render.bind(this);
  }

  range(start, end, length = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i)
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if(name === 'startyear')
      this.props.onChangeSelectedStartyear(value);
    else if (name === 'endyear')
      this.props.onChangeSelectedEndyear(value);
  };

  handleClusterChange(event){
    const target = event.target;
    const value = target.checked;
    const name = target.name;

    this.setState({ 
      [name]: value
    });
    this.props.onChangeSelectedCluster(value);
  };

  render(){
    const menuItems = this.items.map(item => (
      <MenuItem key={item} value={item}>{item}</MenuItem>
    ));

    return (
    <div>
      <FormControl variant="outlined" >
        <InputLabel id="start-year-label">Start Year</InputLabel>
        <Select
          labelId="start-year-label"
          id="start-year"
          name="startyear"
          value={this.state.startyear}
          onChange={this.handleChange}
          label="Start Year"
        >
          {menuItems}
        </Select>
      </FormControl>
      <FormControl variant="outlined" >
        <InputLabel id="end-year-label">End Year</InputLabel>
        <Select
          labelId="end-year-label"
          id="end-year"
          name="endyear"
          value={this.state.endyear}
          onChange={this.handleChange}
          label="End Year"
        >
          {menuItems}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.cluster}
            onChange={this.handleClusterChange}
            name="cluster"
          />
        }
        label="Cluster"
      />
    </div>
  );
  }
}

export default FilterForm;
