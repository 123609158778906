import React, { Component } from 'react'
import './App.css'
import Upload from './upload/Upload'
import SimpleBottomNavigation from './bottomnavigation/SimpleBottomNavigation'
import ForceChart from './charts/forcechart/ForceChart'

class App extends Component {
  constructor(){
    super()
    this.state = { page:0 }
  }

  changePage(page){
    this.setState({page})
  }

  render() {
    return (
    <div>
      <div className="App">
        <div className="Card">
        {this.state.page === 0 &&
          <ForceChart />
        }
        {this.state.page === 1 &&
          <Upload />
        }
        </div>
      </div>
      <SimpleBottomNavigation 
        state={this.state}
        changePage={this.changePage.bind(this)}
      />
    </div>
    )
  }
}

export default App