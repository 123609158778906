import React from 'react'
import './Download.css'

class Download extends React.Component {
  constructor(props) {
    super(props)
    this.download = this.download.bind(this);
  }

  download() {
    // fake server request, getting the file url as response
    setTimeout(() => {
      const response = {
        file: this.props.url,
      };
      // server sent the url to the file!
      // now, let's download:
      window.location.href = response.file;
      // you could also do:
      // window.open(response.file);
    }, 100);
  }
  render() {
    return(
      <button onClick={this.download}> {this.props.title} </button>
    );
  }
}

export default Download