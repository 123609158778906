import React, {Component} from 'react';
import * as d3 from "d3";
import Filter from '../../filterform/Filterform';
import Download from '../../download/Download'

class ForceChart extends Component {
  constructor(props) {
    super(props);
    this.canvas = { 
      width: 1200, 
      height: 800,
      margin: {top: 30, right: 50, bottom: 10, left: 50}
    };
    // this.id = 1;
    this.timer = null; 

    this.state = {
      pollingresult: { status: null, job_id: null},
      startyear: 2000,
      endyear: 2020,
      cluster: 'single',
      numberOfNodes: 0,
      numberOfLinks: 0
    }
    this.drawChart = this.drawChart.bind(this)
    this.handleSelectedStartyear = this.handleSelectedStartyear.bind(this)
    this.handleSelectedEndyear = this.handleSelectedEndyear.bind(this)
    this.getItems = this.getItems.bind(this)
    this.fetchCalculations = this.fetchCalculations.bind(this)
    this.handleClusterCheck = this.handleClusterCheck.bind(this)

    this.fetchCalculations(this.state.startyear, this.state.endyear, this.state.cluster)
  }

  fetchCalculations(startyear, endyear, cluster) {
    fetch(process.env.REACT_APP_DATA_ENDPOINT + '/calculate/crossmatrix/' + startyear + '/' + endyear + '/' + cluster, {
        method: 'get'
    }).then(result => result.json())
      .then(result => this.setState({ pollingresult: result }));

    fetch(process.env.REACT_APP_DATA_ENDPOINT + '/calculate/aggroveryear/' + startyear + '/' + endyear + '/' + cluster, {
        method: 'get'
    }).then(result => result.json())
      .then(result => this.setState({ pollingresult2: result }));
  }

  handleSelectedStartyear(year) {
    this.setState({startyear: year})
    this.fetchCalculations(year, this.state.endyear, this.state.cluster);
  }

  handleSelectedEndyear(year) {
    this.setState({endyear: year})
    this.fetchCalculations(this.state.startyear, year, this.state.cluster);
  }

  handleClusterCheck(blcluster) {
    console.log(blcluster)
    var cluster = "single";
    if(blcluster){
      cluster = "clustered";
    }
    this.setState({cluster: cluster});
    this.fetchCalculations(this.state.startyear, this.state.endyear, cluster);
  }


  componentDidUpdate() {
    if(this.state.pollingresult.status === 'queued' && this.timer === null){
        this.timer = setInterval(()=> this.getItems(), 1000);
    }
    if(this.state.pollingresult.status === 'finished'){
        clearInterval(this.timer)
        this.timer = null;
        this.setState({pollingresult: { status: 'done', job_id: null}})
        this.drawChart(this.state.startyear, this.state.endyear, this.state.cluster);
    }
  }

  componentDidMount() {
    if(this.state.pollingresult.status === null) {
        this.timer = setInterval(()=> this.getItems(), 1000);
    }
    // this.drawChart(this.state.startyear, this.state.endyear);
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.timer = null; 
  }

  getItems() {
    fetch(process.env.REACT_APP_DATA_ENDPOINT + '/job/' + this.state.pollingresult.job_id, {
        method: 'get'
      }).then(result => result.json())
      .then(result => this.setState({ pollingresult: result }));
  }
    
  drawChart(startyear, endyear, cluster) {
    const svg = d3.select(this.node)
    svg.selectAll("*").remove();
    const canvas = this.canvas;

    var width = canvas.width - canvas.margin.left - canvas.margin.right,
        height = canvas.height - canvas.margin.top - canvas.margin.bottom;

    var container = svg.append("g");
    var color = d3.scaleOrdinal(d3.schemeCategory10);

    fetch(process.env.REACT_APP_DATA_ENDPOINT + '/forcedgraph/'  + startyear + '/' + endyear + '/' + cluster, {
        method: 'get'
      }).then(response => {
        return response.json()
      }).then(function(graph) {
        this.setState({numberOfNodes: graph.nodes.length,
                       numberOfLinks: graph.links.length});
        var label = {
            'nodes': [],
            'links': []
        };

        graph.nodes.forEach(function(d, i) {
            label.nodes.push({node: d});
            label.nodes.push({node: d});
            label.links.push({
                source: i * 2,
                target: i * 2 + 1
            });
        });

        var labelNode = container.append("g").attr("class", "labelNodes")
            .selectAll("text")
            .data(label.nodes)
            .enter().append("text")
            .text(function(d, i) { return i % 2 === 0 ? "" : d.node.id; })
            .style("fill", "#555")
            .style("font-family", "Arial")
            .style("font-size", 12)
            .style("pointer-events", "none"); 

        var neigh = function(a, b) {
            return a === b || adjlist[a + "-" + b];
        }

        var ticked = function() {
            node.call(updateNode);
            link.call(updateLink);

            labelLayout.alphaTarget(0.3).restart();
            labelNode.each(function(d, i) {
                if(i % 2 === 0) {
                    d.x = d.node.x;
                    d.y = d.node.y;
                } else {
                    var diffX = d.x - d.node.x;
                    var diffY = d.y - d.node.y;

                    var dist = Math.sqrt(diffX * diffX + diffY * diffY);

                    var shiftX = width * (diffX - dist) / (dist * 2);
                    shiftX = Math.max(-width, Math.min(0, shiftX));
                    var shiftY = 16;
                    this.setAttribute("transform", "translate(" + shiftX + "," + shiftY + ")");
                }
            });
            labelNode.call(updateNode);
        }

        var fixna = function(x) {
            if (isFinite(x)) return x;
            return 0;
        }

        var focus = function(d) {
            var index = d3.select(d3.event.target).datum().index;
            node.style("opacity", function(o) {
                return neigh(index, o.index) ? 1 : 0.1;
            });
            labelNode.attr("display", function(o) {
              return neigh(index, o.node.index) ? "block": "none";
            });
            link.style("opacity", function(o) {
                return o.source.index === index || o.target.index === index ? 1 : 0.1;
            });
        }

        var unfocus = function() {
           labelNode.attr("display", "block");
           node.style("opacity", 1);
           link.style("opacity", 1);
        }

        var updateLink = function(link) {
            link.attr("x1", function(d) { return fixna(d.source.x); })
                .attr("y1", function(d) { return fixna(d.source.y); })
                .attr("x2", function(d) { return fixna(d.target.x); })
                .attr("y2", function(d) { return fixna(d.target.y); });
        }

        var updateNode = function(node) {
            node.attr("transform", function(d) {
                return "translate(" + fixna(d.x) + "," + fixna(d.y) + ")";
            });
        }

        var dragstarted = function(d) {
            d3.event.sourceEvent.stopPropagation();
            if (!d3.event.active) graphLayout.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        var dragged = function(d) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        var dragended = function(d) {
            if (!d3.event.active) graphLayout.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        var labelLayout = d3.forceSimulation(label.nodes)
            .force("charge", d3.forceManyBody().strength(-50))
            .force("link", d3.forceLink(label.links).distance(0).strength(2));

        var graphLayout = d3.forceSimulation(graph.nodes)
            .force("charge", d3.forceManyBody().strength(-3000))
            .force("center", d3.forceCenter(width / 2, height / 2))
            .force("x", d3.forceX(width / 2).strength(1))
            .force("y", d3.forceY(height / 2).strength(1))
            .force("link", d3.forceLink(graph.links).id(function(d) {return d.id; }).distance(50).strength(1))
            .on("tick", ticked);

        var adjlist = [];

        graph.links.forEach(function(d) {
            adjlist[d.source.index + "-" + d.target.index] = true;
            adjlist[d.target.index + "-" + d.source.index] = true;
        });

        svg.call(
            d3.zoom()
                .scaleExtent([.1, 4])
                .on("zoom", function() { container.attr("transform", d3.event.transform); })
        );

        var link = container.append("g").attr("class", "links")
            .selectAll("line")
            .data(graph.links)
            .enter()
            .append("line")
            .attr("stroke", "#aaa")
            .attr("stroke-width", "1px");

        var node = container.append("g").attr("class", "nodes")
            .selectAll("g")
            .data(graph.nodes)
            .enter()
            .append("circle")
            .attr('r', function (d) {
                return d.size;
            })
            .attr("fill", function(d) { return color(d.group); })

        node.on("mouseover", focus).on("mouseout", unfocus);

        node.call(
            d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended)
        );

        node.on("mouseover", focus).on("mouseout", unfocus);
        }.bind(this)
        );
    }
    
  render(){
    return(
    <div>
    <Filter startyear={this.state.startyear} 
       onChangeSelectedStartyear={this.handleSelectedStartyear}
       endyear={this.state.endyear} 
       onChangeSelectedEndyear={this.handleSelectedEndyear}
       cluster={false}
       onChangeSelectedCluster={this.handleClusterCheck}
       />
    <svg ref={node => this.node = node}
      width={800} height={500}>
    </svg>
    <div>
      number of Nodes: {this.state.numberOfNodes} <br/>      
      number of Links: {this.state.numberOfLinks} <br/>
      number of avg Links per Node : {(this.state.numberOfLinks/this.state.numberOfNodes).toFixed(2)}
    </div>
        <div>
          <Download title='crossmatrix'
           url={process.env.REACT_APP_DATA_ENDPOINT + '/download/crossmatrix/' + this.state.startyear + '/' + this.state.endyear + '/' + this.state.cluster}/>

          <Download title='over years'
           url={process.env.REACT_APP_DATA_ENDPOINT + '/download/aggroveryear/' + this.state.startyear + '/' + this.state.endyear + '/' + this.state.cluster}/>
        </div>
    </div>);
  }
}
    
export default ForceChart;